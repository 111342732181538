<template>
  <b-container fluid>
    <b-overlay :show="saveloading">
        <b-col v-if="loadHistory" md="12" lg="12" sm="12">
          <b-overlay :show="true" class="p-5">
            <div class="text-center">
              <h5>Pending payment checking...</h5>
            </div>
          </b-overlay>
        </b-col>
        <b-col v-else md="12" lg="12" sm="12">
          <ValidationObserver ref="from" v-slot="{ handleSubmit }">
            <b-form  @submit.prevent="handleSubmit(saveFormData)" >
              <table v-if="history.length" class="table table-bordered table-sm">
                <tr>
                  <th colspan="4" class="text-center">History</th>
                </tr>
                <tr>
                    <th>{{ $t('fee_name.fee_name') }}</th>
                    <th>{{ $t('globalTrans.amount') }}</th>
                    <th>{{ $t('globalTrans.vat') }} (%) </th>
                    <th>
                      <slot v-if="payment.service_id === 11">
                        {{ $t('externalLrcpn.ait') }}
                      </slot>
                      <slot v-else>
                        {{ $t('globalTrans.tax') }}
                      </slot>
                      (%)
                    </th>
                </tr>
                <tr v-for="(item, index) in history" :key="index">
                  <td>{{ getFeeName(item) }}</td>
                  <td>{{ $n(item.amount) }}</td>
                  <td>({{ $n(item.vat) }}%) = {{ (item.amount_vat) }}</td>
                  <td>({{ $n(item.tax) }}%) = {{ (item.amount_tax) }}</td>
                </tr>
                <tr>
                  <th>{{ $t('globalTrans.subtotal') }}</th>
                  <td>{{ getTotal(history) }}</td>
                  <td>{{ getTotalVat(history) }}</td>
                  <td>{{ getTotalTax(history) }}</td>
                </tr>
                <tr>
                  <th colspan="2">{{ $t('globalTrans.total') }}</th>
                  <td colspan="2">{{ getTotal(history) + getTotalVat(history) + getTotalTax(history) }}</td>
                </tr>
              </table>
              <div v-else>
                <div class="row" v-if="allPayment.length">
                  <slot v-if="[51, 60, 66].includes(payment.step_id) && payment.application_type === 1">
                    <b-col lg="6" sm="12">
                      <ValidationProvider name="Have you given security for this BIN number before?" vid="have_you_given_securaty_bin_number">
                          <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="have_you_given_securaty_bin_number"
                          >
                          <template v-slot:label>
                              {{$t('externalLrcpn.have_you_given_securaty_bin_number')}}
                          </template>
                          <b-form-radio-group
                            v-model="isSecurityMoneySend.securatyPaid"
                            :options="manualList"
                          >
                          </b-form-radio-group>
                          </b-form-group>
                      </ValidationProvider>
                    </b-col>
                    <div class="col-md-6" v-if="isSecurityMoneySend.securatyPaid">
                      <ValidationProvider name="Attachment" vid="attachment" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="12"
                            label-for="attachment"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                                {{ $t('globalTrans.attachment') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-file
                              v-on:change="onFileChange"
                              id="attachment"
                              v-model="isSecurityMoneySend.attachment"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-file>
                            <div class="invalid-feedback">
                                {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                    </div>
                    <hr/>
                  </slot>
                  <div class="col-md-12">
                    <table class="table table-bordered table-sm">
                      <tr>
                          <th>{{ $t('fee_name.fee_name') }}</th>
                          <th>{{ $t('globalTrans.amount') }}</th>
                          <th>{{ $t('globalTrans.vat') }} (%) </th>
                          <th>
                            <slot v-if="payment.service_id === 11">
                              {{ $t('externalLrcpn.ait') }}
                            </slot>
                            <slot v-else>
                              {{ $t('globalTrans.tax') }}
                            </slot>
                            (%)
                          </th>
                      </tr>
                      <tr v-for="(item, index) in allPayment" :key="index">
                        <td>
                          <b-form-checkbox v-if="isChoosePayment(item.service_id)"
                            class="mt-2"
                            v-model="item.is_choose_payment"
                            disabled
                            >{{ getFeeName(item) }}</b-form-checkbox>
                            <span v-else>
                              {{ getFeeName(item) }}
                              <slot v-if="item.amount === 2500 && payment.service_id === 11">
                                ({{ $t('externalLrcpn.include_expire_fee') }})
                              </slot>
                            </span>
                        </td>
                        <td>{{ $n(item.amount) }}</td>
                        <td>({{ $n(item.vat) }}%) = {{ $n(item.amount_vat) }}</td>
                        <td>({{ $n(item.tax) }}%) = {{ $n(item.amount_tax) }}</td>
                      </tr>
                      <tr>
                        <th>{{ $t('globalTrans.subtotal') }}</th>
                        <td>{{ $n(getTotal(allPayment)) }}</td>
                        <td>{{ $n(getTotalVat(allPayment)) }}</td>
                        <td>{{ $n(getTotalTax(allPayment)) }}</td>
                      </tr>
                      <tr>
                        <th colspan="2">{{ $t('globalTrans.total') }}</th>
                        <td colspan="2">{{ $n(getTotal(allPayment) + getTotalVat(allPayment) + getTotalTax(allPayment)) }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                  <div class="col-md-12 text-right">
                      <b-button type="submit" variant="primary" class="mr-2 btn-sm">{{ $t('externalLrcpn.pay_now') }}</b-button>
                      <b-button variant="danger" class="mr-1 btn-sm" @click="$bvModal.hide(payment.application_type === 1 ? `modal-payment` : `modal-payment-renew`)">{{ $t('globalTrans.cancel') }}</b-button>
                  </div>
              </div>
            </b-form>
          </ValidationObserver>
        </b-col>
    </b-overlay>
    <!-- <pre>{{ isSecurityMoneySend }}</pre>
    <pre>{{ payment }}</pre> -->
    <!-- <pre>{{ pledge }}</pre> -->
  </b-container>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { paymentStoreOnline, getTypeCheckboxPaymentList, getLabPaymentFromMainPayment } from './../../../../api/routes'
import flatpickr from 'flatpickr'
import Renew from './renew'
import { ValidationObserver } from 'vee-validate'

export default {
  props: ['payment'],
  components: {
    ValidationObserver
  },
  data () {
    return {
        saveloading: false,
        loadHistory: false,
        type: 1,
        isSecurityMoneySend: {
          securatyPaid: false,
          attachment: ''
        },
        allPayment: [],
        labPayment: false,
        total_amount: 0,
        total_vat: 0,
        total_tax: 0,
        pledge: false,
        history: []
    }
  },
  mounted () {
    setTimeout(this.setPicker, 10000)
  },
  created () {
    this.checkHistory()
    if (this.payment.application_type === 1) {
      this.getPayment()
    }
    if (this.payment.application_type === 1 && this.checkLabPayment()) {
      this.getLabPayment()
    }
    const checkPayment = this.checkPayment()
    if (checkPayment.field_payment.length > 1) {
      this.typeWithCheckboxPayment(this.payment.service_id, checkPayment.field_payment, 1, checkPayment.ekpay_challan_no)
    }
    if (checkPayment.checkbox_payment.length > 1) {
      this.typeWithCheckboxPayment(this.payment.service_id, checkPayment.checkbox_payment, 2, checkPayment.ekpay_challan_no)
    }
    if (this.payment.application_type === 2 && checkPayment.checkbox_payment.length === 1 && checkPayment.field_payment.length === 1) {
      Renew.getRenewPayment(this.payment, this)
    }
  },
  watch: {
    'isSecurityMoneySend.securatyPaid': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        if (newVal) {
          this.pledge = true
        } else {
          this.pledge = false
        }
        this.getPayment()
      }
    }
  },
  computed: {
    manualList: function () {
      return [
        { value: true, text: this.$i18n.locale === 'bn' ? 'হ্যাঁ' : 'Yes' },
        { value: false, text: this.$i18n.locale === 'bn' ? 'না' : 'No' }
      ]
    },
    paymentType () {
      return [
        {
          text: this.$i18n.locale === 'bn' ? 'অনলাইন' : 'Online',
          value: 1
        },
        {
          text: this.$i18n.locale === 'bn' ? 'অফলাইন' : 'Offline',
          value: 2
        }
      ]
    }
  },
  methods: {
    onFileChange (e) {
      // this.attachmentDemo = e.target.files[0]
      this.getBase64(e.target.files[0]).then(res => {
        this.isSecurityMoneySend.attachment = res
      })
    },
    getBase64 (file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader()
        let imgResult = ''
        reader.readAsDataURL(file)
        reader.onload = function () {
          imgResult = reader.result
        }
        reader.onerror = function (error) {
          reject(error)
        }
        reader.onloadend = function () {
          resolve(imgResult)
        }
      })
    },
    childMethod () {
      this.$emit('child-method', this.pledge)
    },
    setPicker () {
      flatpickr('.datepic', {})
    },
    getTotal (data) {
      const total = data.filter(item => item.is_choose_payment === true).reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.amount)
      }, 0)
      this.total_amount = total
      return total
    },
    getTotalVat (data) {
      const total = data.filter(item => item.is_choose_payment === true).reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.amount_vat)
      }, 0)
      this.total_vat = total
      return total
    },
    getTotalTax (data) {
      const total = data.filter(item => item.is_choose_payment === true).reduce(function (a, b) {
        return parseFloat(a) + parseFloat(b.amount_tax)
      }, 0)
      this.total_tax = total
      return total
    },
    getPayment () {
      let fees = this.$store.state.ExternalLrcpn.lrcpnObj.stepFeeList.filter(item => item.step_id === parseInt(this.payment.step_id) && item.service_id === parseInt(this.payment.service_id) && item.fee_id !== 0 && item.lab_fee === 0)
      if (this.pledge) {
        fees = fees.filter(item => !(item.fee_id === 11 || item.fee_id === 13 || item.fee_id === 15))
      }
      if (fees.length) {
        this.allPayment = JSON.parse(JSON.stringify(fees)).map(item => {
          const vat = (item.amount * (item.vat ? parseInt(item.vat) : 0)) / 100
          const tax = (item.amount * (item.tax ? parseInt(item.tax) : 0)) / 100
          return Object.assign(item,
          {
            amount_vat: vat,
            amount_tax: tax,
            bank_name: '',
            bank_name_tax: '',
            bank_name_vat: '',
            branch_name: '',
            branch_name_tax: '',
            branch_name_vat: '',
            challan_no: '',
            challan_no_tax: '',
            challan_no_vat: '',
            pay_date: null,
            pay_date_vat: null,
            pay_date_tax: null,
            amount_type: 1,
            fee_name: '',
            is_choose_payment: true
          })
        })
      }
    },
    getFeeName (fee) {
      if (!fee.fee_name) {
        if (this.payment.application_type === 2) {
          if (fee.amount_type === 6) {
            return this.$t('externalLrcpn.expire_fee')
          } else {
            return this.$t('externalLrcpn.renew_fee')
          }
        }
        const fees = this.$store.state.ExternalLrcpn.lrcpnObj.feeNamesList.find(item => item.value === fee.fee_id)
        if (typeof fees === 'undefined') {
            return ''
        } else {
            return fees.text
        }
      } else {
        return (this.$i18n.locale === 'bn') ? fee.fee_name_bn : fee.fee_name
      }
    },
    async typeWithCheckboxPayment (serviceId, paymentField, paymentType, challanNo, isExpireAmout = 0) {
      const formData = this.$store.state.ExternalLrcpn.lrcpnObj.serviceStepForm.find(item => (item.service_id === serviceId && item.form_type === 2))
      const formId = formData.form_id
      const params = {
        id: formId,
        application_id: this.payment.application_id,
        service_id: this.payment.service_id,
        tabList: this.getTabList(),
        payment_field: paymentField,
        grid_step_id: this.getGridStep(this.payment.service_id).step_id
      }
      this.saveloading = true
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, getTypeCheckboxPaymentList, params)
        this.saveloading = false
        if (result.success) {
          this.typeWisePaymentPush(result, paymentField, paymentType, challanNo, isExpireAmout)
        }
    },
    typeWisePaymentPush (result, paymentField, paymentType, challanNo, isExpireAmout = 0) {
      if (paymentType === 1) {
        const payment = parseInt(result.payment[paymentField])
        result.data.dropdown_options.forEach((item, index) => {
          const amount = this.getAmount(item, isExpireAmout)
          if (index === payment) {
            // 3 amount type select payment
            let amountType = 3
            if (isExpireAmout) {
              amountType = 6
            }
            this.pushPayment(amount, payment, item, amountType, challanNo)
          }
        })
      }
      if (paymentType === 2) {
        const payment = JSON.parse(result.payment[paymentField])
        let paym = payment
        if (typeof payment === 'string') {
          paym = JSON.parse(payment)
        } else {
          paym = payment
        }
        this.paymentList = result.data.dropdown_options.forEach((item, index) => {
          const amount = this.getAmount(item, isExpireAmout)
          paym.forEach(fee => {
            if (index === fee) {
              // 4 amount type checkbox payment
              let amountType = 4
              if (isExpireAmout) {
                amountType = 6
              }
              this.pushPayment(amount * result.roundYear, fee, item, amountType, challanNo)
            }
          })
        })
      }
    },
    getAmount (item, isExpireAmout = 0) {
        let amount = 0
        if (isExpireAmout) {
          amount = this.item.expire_amount
        } else {
          amount = this.payment.application_type === 1 ? item.amount : item.renew_amount
        }
        return amount
    },
    pushPayment (amount, feeId, item, amountType, challanNo) {
        this.allPayment.push({
          step_id: this.payment.step_id,
          service_id: this.payment.service_id,
          fee_id: feeId,
          amount_type: amountType,
          amount: amount,
          amount_vat: (amount * (item.vat ? parseInt(item.vat) : 0)) / 100,
          amount_tax: (amount * (item.tax ? parseInt(item.tax) : 0)) / 100,
          vat: item.vat ? parseInt(item.vat) : 0,
          tax: item.tax ? parseInt(item.tax) : 0,
          bank_name: '',
          bank_name_tax: '',
          bank_name_vat: '',
          branch_name: '',
          branch_name_tax: '',
          branch_name_vat: '',
          ekpay_challan_no: challanNo,
          challan_no: '',
          challan_no_tax: '',
          challan_no_vat: '',
          pay_date: null,
          pay_date_vat: null,
          pay_date_tax: null,
          fee_name: item.type_name,
          fee_name_bn: item.type_name_bn,
          is_choose_payment: true
        })
    },
    getTabList () {
      const formId = this.getGridStep(this.payment.service_id).form_id
      const form = this.$store.state.ExternalLrcpn.lrcpnObj.dynamicFormList.find(item => item.value === formId)
      if (typeof form === 'undefined') {
        return []
      }
      return form.tabs
    },
    getGridStep (serviceId) {
      const tmpService = this.$store.state.ExternalLrcpn.lrcpnObj.serviceStepForm.find(item => (item.service_id === serviceId && item.form_type === 2))
      return tmpService
    },
    async saveFormData () {
        let tmpPay = {}
        const amount = this.total_amount + this.total_vat + this.total_tax
        if (amount <= 0) {
          this.$toast.error({
            title: 'Error',
            message: 'Please choose minimum one payment.'
          })
          return false
        }
        const name = this.$store.state.Auth.authUser.name
        const email = this.$store.state.Auth.authUser.email
        if (this.history.length) {
          tmpPay = Object.assign(this.payment, { details: this.allPayment, transId: this.history[0].transaction_no, paid_type: 1, amount: amount, applicant_name: name, email: email, labPayment: this.labPayment, isSecurityMoneySend: this.isSecurityMoneySend })
        } else {
          tmpPay = Object.assign(this.payment, { details: this.allPayment, transId: '', paid_type: 1, amount: amount, applicant_name: name, email: email, labPayment: this.labPayment, isSecurityMoneySend: this.isSecurityMoneySend })
        }
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadingState = { loading: false, listReload: false }
        this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: true })
        this.saveloading = true
        result = await RestApi.postData(licenseRegistrationServiceBaseUrl, paymentStoreOnline, tmpPay)
        this.saveloading = false
        loadingState.listReload = true
        this.$store.dispatch('ExternalUserIrrigation/mutateExternalCommonProperties', { loading: false })
        this.$store.dispatch('mutateCommonProperties', loadingState)
        if (result.success) {
          if (result.success === 2) {
            this.$toast.success({
              title: 'Success',
              message: result.message
            })
          } else {
            window.location = result.url
          }
        } else {
          this.$refs.form.setErrors(result.errors)
          this.$toast.error({
            title: 'Error',
            message: result.message
          })
        }
    },
    async checkHistory () {
      const params = {
        application_id: this.payment.application_id,
        all_app_id: this.payment.all_app_id,
        service_id: this.payment.service_id,
        application_type: this.payment.application_type,
        step_id: this.payment.step_id,
        file_no: this.payment.file_no,
        paid_type: 1
      }
      this.loadHistory = true
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, '/application/payment/history', params)
      this.loadHistory = false
      if (result.success) {
        const tmp = result.data.map(item => {
          return Object.assign(item, { is_choose_payment: true })
        })
        this.history = tmp
        if (result.pledge) {
          this.pledge = result.pledge
          if (this.pledge) {
            this.isSecurityMoneySend.securatyPaid = true
          }
          if (this.payment.application_type === 1) {
            this.getPayment()
          }
          this.childMethod()
        }
        flatpickr('.datepic', {})
      } else {
        this.history = []
        if (result.pledge) {
          this.pledge = result.pledge
          if (this.payment.application_type === 1) {
            this.getPayment()
          }
          this.childMethod()
        }
      }
    },
    checkPayment () {
      const tmp = this.$store.state.ExternalLrcpn.lrcpnObj.serviceSteps.find(item => item.step_id === this.payment.step_id && item.service_id === this.payment.service_id)
      return typeof tmp === 'undefined' ? { checkbox_payment: false, field_payment: false } : tmp
    },
    checkLabPayment () {
      const tmp = this.$store.state.ExternalLrcpn.lrcpnObj.serviceSteps.find(item => item.step_id === this.payment.step_id && item.service_id === this.payment.service_id && item.lab_fee === 1)
      return typeof tmp === 'undefined' ? 0 : 1
    },
    async getLabPayment () {
      const params = {
        application_id: this.payment.application_id,
        service_id: this.payment.service_id
      }
      this.saveloading = true
      const result = await RestApi.getData(licenseRegistrationServiceBaseUrl, getLabPaymentFromMainPayment, params)
        this.saveloading = false
        if (result.success) {
          this.labPayment = true
          this.allPayment.push(result.data)
        }
    },
    isChoosePayment (serviceId) {
      const tmp = this.$store.state.ExternalLrcpn.lrcpnObj.serviceNamesList.find(item => item.value === serviceId)
      return typeof tmp === 'undefined' ? false : tmp.choose_payment
    }
  }
}
</script>

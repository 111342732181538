
<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
        <b-table-simple striped bordered small class="mt-2">
          <b-tr>
            <b-th>{{ $t('globalTrans.sl_no') }}</b-th>
            <b-th>{{ $t('dynamic_form.note') }}</b-th>
            <b-th>{{ $t('li_step.note_type') }}</b-th>
            <b-th>{{ $t('globalTrans.attachment') }}</b-th>
          </b-tr>
          <b-tr v-for="(reject, index) in item.app_rejects " :key="index">
            <b-td>{{ $n(index+1) }}</b-td>
            <b-td>{{ reject.note }}</b-td>
            <b-td>
              <slot v-if="reject.status === 1">
                {{$t('budget.approve')}}
              </slot>
              <slot v-else-if="reject.status === 2">
                {{$t('budget.reject') }}
              </slot>
              <slot v-else-if="reject.status === 3">
                {{$t('li_step.review') }}
              </slot>
            </b-td>
            <b-td>
                <a target="_blank" class="btn btn-sm btn-success" v-if="reject.attachment" :href="lrcpanBaseUrl + 'uploads/lrcpn/' + reject.attachment"><i style="margin: 15px 1px 15px !important; font-size: 12px" class="ri-download-line"></i></a>
            </b-td>
          </b-tr>
        </b-table-simple>
      </template>
    </iq-card>
  </b-container>
</template>
<script>
import { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
export default {
  props: ['item'],
  components: {
  },
  data () {
    return {
      note: '',
      lrcpanBaseUrl: licenseRegistrationServiceBaseUrl
    }
  },
  created () {
    this.note = this.item
  }
}
</script>

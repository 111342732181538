<template>
  <b-container fluid>
    <b-overlay :show="saveLoading">
        <b-col md="12" lg="12" sm="12">
          <ValidationObserver ref="form" v-slot="{ handleSubmit }">
            <b-form  @submit.prevent="handleSubmit(saveFormData)" >
              <div>
                <b-table-simple bordered>
                    <b-tr>
                      <b-th style="width: 15%;">{{ $t('globalTrans.org_name') }}</b-th>
                      <b-td style="width: 35%;text-align: left;">{{ getOrganizationName(formData.org_id) }}</b-td>
                      <b-th style="width: 15%;">{{ $t('service_name.service_name') }}</b-th>
                      <b-td style="width: 35%;text-align: left;">{{ getServiceName(formData.service_id) }}</b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>{{ $t('li_step.application_id') }}</b-th>
                      <b-td>{{ formData.application_id  }}</b-td>
                      <b-th>{{ $t('externalLrcpn.type') }}</b-th>
                      <b-td>
                        <slot v-if="formData.type === 1">{{ $t('globalTrans.new') }}</slot>
                        <slot v-if="formData.type === 2">{{ $t('globalTrans.renew') }}</slot>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-th>{{ $t('elearning_config.office_type') }}</b-th>
                      <b-td>{{ getOfficeTypeName(formData.office_type_id)  }}</b-td>
                      <b-th>{{ $t('globalTrans.office') }}</b-th>
                      <b-td>{{ getOfficeName(formData.office_id)  }}</b-td>
                    </b-tr>
                </b-table-simple>
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider name="Certificate Change Details" vid="note" rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="3"
                            label-for="note"
                            slot-scope="{ valid, errors }"
                            style="font-size: 13px"
                        >
                            <template v-slot:label>
                                {{ $t('externalLrcpn.certificate_change_details') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-textarea
                              rows="2"
                              id="note"
                              v-model="formData.note"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="error invalid-feedback d-flex" role="alert">
                              {{ errors[0] }}
                            </div>
                        </b-form-group>
                      </ValidationProvider>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-md-12 text-right">
                  <b-button type="submit" variant="primary" class="mr-2 btn-sm">{{ $t('globalTrans.submit') }}</b-button>
                  <b-button variant="danger" class="mr-1" @click="$bvModal.hide('certificate-change-request')">{{ $t('globalTrans.cancel') }}</b-button>
                </div>
              </div>
            </b-form>
          </ValidationObserver>
        </b-col>
    </b-overlay>
  </b-container>
</template>
<script>
import RestApi, { licenseRegistrationServiceBaseUrl } from '@/config/api_config'
import { certificateChangeRequest } from './../../api/routes'
export default {
  props: ['item'],
  components: {
  },
  data () {
    return {
      saveLoading: false,
      loadHistory: false,
      formData: {
        all_service_application_id: this.item.id,
        org_id: this.item.org_id,
        service_id: this.item.service_id,
        application_id: this.item.application_id,
        type: this.item.application_type,
        office_type_id: this.item.office_type_id,
        office_id: this.item.office_id,
        start_date: this.item.created_at,
        end_date: this.item.expire_date,
        note: ''
      },
      history: []
    }
  },
  created () {
    // this.checkHistory()
  },
  methods: {
    getServiceName (id) {
      const serviceType = this.$store.state.ExternalLrcpn.lrcpnObj.serviceNamesList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return serviceType !== undefined ? serviceType.text_bn : ''
      } else {
        return serviceType !== undefined ? serviceType.text_en : ''
      }
    },
    getOrganizationName (id) {
      const serviceType = this.$store.state.ExternalUserIrrigation.commonObj.organizationProfileList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return serviceType !== undefined ? serviceType.text_bn : ''
      } else {
        return serviceType !== undefined ? serviceType.text_en : ''
      }
    },
    getOfficeTypeName (id) {
      const officeType = this.$store.state.ExternalUserIrrigation.commonObj.officeTypeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return officeType !== undefined ? officeType.text_bn : ''
      } else {
        return officeType !== undefined ? officeType.text_en : ''
      }
    },
    getOfficeName (id) {
      const office = this.$store.state.ExternalUserIrrigation.commonObj.officeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return office !== undefined ? office.text_bn : ''
      } else {
        return office !== undefined ? office.text_en : ''
      }
    },
    async saveFormData () {
      let result = null
      this.saveLoading = true
      const loadinState = { loading: false, listReload: false }

      result = await RestApi.postData(licenseRegistrationServiceBaseUrl, certificateChangeRequest, this.formData)

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.saveLoading = false
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })
        this.$bvModal.hide('certificate-change-request')
      } else {
        this.saveLoading = false
        this.$refs.form.setErrors(result.errors)
      }
      this.loading = false
    }
  }
}
</script>
<style>
  .b-table-simple {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }

  .b-tr:hover {
    background-color: #f5f5f5;
  }

  .b-th, .b-td {
    border: 1px solid #ddd;
    padding: 7px;
    background-color: #552323;
  }

  .b-tr:nth-child(even) {
    background-color: #643232;
  }
</style>

<template>
  <div>
    <slot v-if="item.expire_date && item.type !== 3">
      <slot v-if="getDaysDiffByDate(item.expire_date, new Date().toISOString().slice(0, 10)) < 0">
        <span class="badge pay-status badge-success w-100 text-white font-weight-bold"> {{ item.expire_date | dateFormat }} </span>
      </slot>
      <slot v-else-if="getDaysDiffByDate(item.expire_date, new Date().toISOString().slice(0, 10)) <= 90">
        <span class="blink_me badge pay-status badge-warning w-100 text-white font-weight-bold" v-if="item.expire_date">
          {{ item.expire_date | dateFormat }}
        </span>
      </slot>
      <slot v-else>
        <span class="blink_me badge pay-status badge-danger w-100 text-white font-weight-bold">
          {{ item.expire_date | dateFormat }}
        </span>
      </slot>
    </slot>
    <slot v-else>
      <span class="badge pay-status badge-success w-100 text-white font-weight-bold" v-if="item.expire_date">
        {{ item.expire_date | dateFormat }}
      </span>
    </slot>
  </div>
</template>
<script>
export default {
  props: ['item'],
  data () {
    return {
        step_id: 0
    }
  },
  created () {
  },
  methods: {
    getDaysDiffByDate (start, end) {
      const date1 = new Date(start)
      const date2 = new Date(end)
      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24
      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime()
      // Calculating the no. of days between two dates
      const diffInDays = Math.round(diffInTime / oneDay)
      return diffInDays
    }
  }
}
</script>
